import React from 'react';
import { injectIntl } from 'react-intl';
import { StaticImage } from 'gatsby-plugin-image';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import RecommendationCard from './recommendationCard';
import * as styles from './recommendedSection.module.scss';
import DottedFrame from './dottedFrame';

const RecommendedSection = ({ intl: { formatMessage } }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const recommendations = [
    {
      id: 'saveTime',
      title: 'businessRecommendationSaveTimeTitle',
      content: 'businessRecommendationSaveTime',
      isRight: false,
    },
    {
      id: 'safeSelection',
      title: 'businessRecommendationSafeSelectionTitle',
      content: 'businessRecommendationSafeSelection',
      isRight: false,
    },
    {
      id: 'technicalValidation',
      title: 'businessRecommendationTechnicalValidationTitle',
      content: 'businessRecommendationTechnicalValidation',
      isRight: false,
    },
    {
      id: 'interestedCandidates',
      title: 'businessRecommendationInterestedCandidatesTitle',
      content: 'businessRecommendationInterestedCandidates',
      isRight: false,
    },
    {
      id: 'simplifyProcesses',
      title: 'businessRecommendationSimplifyProcessesTitle',
      content: 'businessRecommendationSimplifyProcesses',
      isRight: true,
    },
    {
      id: 'withoutPermanence',
      title: 'businessRecommendationWithoutPermanenceTitle',
      content: 'businessRecommendationWithoutPermanence',
      isRight: true,
    },
    {
      id: 'payForService',
      title: 'businessRecommendationPayForServiceTitle',
      content: 'businessRecommendationPayForService',
      isRight: true,
    },
    {
      id: 'competitiveRates',
      title: 'businessRecommendationCompetitiveRatesTitle',
      content: 'businessRecommendationCompetitiveRates',
      isRight: true,
    },
  ];

  const leftRecommendations = recommendations.filter((rec) => !rec.isRight);
  const rightRecommendations = recommendations.filter((rec) => rec.isRight);

  return (
    <div className={styles.recommended_section}>
      <Typography variant="h2" className={styles.recommended_section__title}>
        {formatMessage({ id: 'businessRecommendationTitle' })}
      </Typography>
      <Typography variant="h6" className={styles.recommended_section__subtitle}>
        {formatMessage({ id: 'businessRecommendationSubtitle' })}
      </Typography>
      {isMdUp && <DottedFrame />}
      <Grid
        container
        spacing={4}
        columnSpacing={{ xs: 1, md: 0 }}
        className={styles.grid_container}
      >
        <Grid item xs={12} md={4} className={styles.left_column}>
          <Box mx={0}>
            {leftRecommendations.map((rec) => (
              <RecommendationCard
                key={rec.id}
                title={formatMessage({ id: rec.title })}
                content={formatMessage({ id: rec.content })}
                isRight={rec.isRight}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={4} className={styles.central_image}>
          <StaticImage
            src="../../../../images/central_image.webp"
            alt="Central"
            placeholder="blurred"
            loading="lazy"
            layout="constrained"
            formats={['auto', 'webp', 'avif']}
            objectFit="contain"
          />
        </Grid>

        <Grid item xs={12} md={4} className={styles.right_column}>
          <Box mx={0}>
            {rightRecommendations.map((rec) => (
              <RecommendationCard
                key={rec.id}
                title={formatMessage({ id: rec.title })}
                content={formatMessage({ id: rec.content })}
                isRight={rec.isRight}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default injectIntl(RecommendedSection);
