// extracted by mini-css-extract-plugin
export var MuiGridRoot = "recommendedSection-module__MuiGrid-root___xlRBH";
export var background_images = "recommendedSection-module__background_images___s8sil";
export var body_background = "#f8f9fa";
export var card = "recommendedSection-module__card___DiIch";
export var central_image = "recommendedSection-module__central_image___v92c-";
export var chip = "recommendedSection-module__chip___W9qvf";
export var container = "recommendedSection-module__container___m5y4N";
export var content = "recommendedSection-module__content___vJzaH";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "recommendedSection-module__full_img___T-iey";
export var full_imgSmall = "recommendedSection-module__full_img--small___2scZI";
export var gray_21 = "recommendedSection-module__gray_21___9mb1r";
export var grid_container = "recommendedSection-module__grid_container___kCGMr";
export var image_container = "recommendedSection-module__image_container___7PHhW";
export var image_item = "recommendedSection-module__image_item___nnCxG";
export var left = "recommendedSection-module__left___EmgzE";
export var left_column = "recommendedSection-module__left_column___TDW+k";
export var lg = "1200px";
export var logo = "recommendedSection-module__logo___eP3aS";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "recommendedSection-module__primary___UjR+T";
export var primary_light = "#e0f9f3";
export var recommendation_card = "recommendedSection-module__recommendation_card___OQ0d3";
export var recommended_section = "recommendedSection-module__recommended_section___DUBGJ";
export var recommended_section__subtitle = "recommendedSection-module__recommended_section__subtitle___8AQdi";
export var recommended_section__title = "recommendedSection-module__recommended_section__title___0Q5Mv";
export var right = "recommendedSection-module__right___gb--G";
export var right_column = "recommendedSection-module__right_column___n2Zws";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "recommendedSection-module__title___a36Mj";
export var white = "#fff";
export var white_button = "recommendedSection-module__white_button___VEDCG";
export var xl = "1536px";
export var xxl = "2500px";