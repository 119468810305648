import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './recommendedSection.module.scss';

const RecommendationCard = ({ title, content, isRight }) => (
  <div className={`${styles.recommendation_card} ${isRight ? styles.right : styles.left}`}>
    <h4>{title}</h4>
    <p>{content}</p>
  </div>
);

RecommendationCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isRight: PropTypes.bool.isRequired,
};

export default RecommendationCard;
